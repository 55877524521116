import React from "react"
import { useStaticQuery, graphql} from "gatsby"
import Layout from "./layout"
import SEO from "./seo"
import Img from "gatsby-image"



const DoctorPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle {
        edges {
          node {
            content {
              childMarkdownRemark {
                html
              }
            }
            id
            title
            cover {
              localFile {
                url
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const articles = data.allContentfulArticle.edges
  const currentArticle = articles.filter(({ node: a }) => a.id === props.pageContext.article.id )[0].node
  currentArticle['type'] = 'article'
  return (
    <Layout >
      <SEO schemaData={currentArticle} title={currentArticle.title}/>
      <div className="px-4">
        <h1 className=" max-w-2xl mx-auto text-3xl text-navy-700 font-serif font-bold tracking-wide leading-snug mb-6 mt-4 lg:mb-10 lg:mt-16">{currentArticle.title}</h1>
        <Img fluid={currentArticle.cover.localFile.childImageSharp.fluid} className="max-w-2xl mx-auto mb-16" />
        <div className="mx-auto container text-navy-700">
          <div dangerouslySetInnerHTML={{ __html: currentArticle.content.childMarkdownRemark.html }} className="article mx-auto max-w-2xl leading-relaxed tracking-wide" />
        </div>
      </div>
    </Layout>
  )
}

export default DoctorPage